// RECETTE API BASE URL
var api = "https://taf-django.withvolkeno.com/api/";

// DEMO API BASE URL
// var api = 'https://demo.api.taf-django.withvolkeno.com/api/'

// PREPROD API BASE URL
// var api = "https://api.tafafricaglobal.com/api/";

export default api;

export const TYPE_PRICE = [
  { label: "Month", value: "per_month" },
  { label: "Year", value: "per_annum" },
];

export function displayPriceType(type) {
  if (type) {
    const finded = TYPE_PRICE.find((t) => t.value === type);
    if (finded) return `/${finded.label}`;
    return "";
  }
  return "";
}
export const MIN_MAX = {
  min: 0,
  max: 100000000,
};

export const MAX_SIZE = 10000;

export const PROJECT_STATUS = {
  new: "New",
  sold_out: "Sold out",
  coming_soon: "Coming soon",
  just_launched: "Just launched",
  finished: "Finished",
  final_units_remaining: "Final units remaining",
};

export const recaptcha = {
  key: "6LdkjjQiAAAAAMxpug0upJa2tU70KC3Aqfd3CIqo",
  lang: "en",
};
