// PREPROD APP BASE URL
// var baseUrlFront = "https://preprod.tafafricaglobal.com/";

// PROD APP BASE URL
// var baseUrlFront = "https://tafafricaglobal.com/";

// DEV APP BASE URL
var baseUrlFront = "https://taf-app.withvolkeno.com/";

export default baseUrlFront;
